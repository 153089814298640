.site-layout {
  padding: 0 50px;
  margin-top: 64px;
}

.site-layout-background {
  background: #fff;
  padding: 24px;
  min-height: 380px;
}
